@font-face {
  font-family: "Graphik Regular";
  src: url("./fonts/Graphik-Regular-Web.eot");
  src: url("./fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("./fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Medium";
  src: url("./fonts/Graphik-Medium-Web.eot");
  src: url("./fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("./fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

body {
  font-family: "Graphik Regular", Helvetica, Arial, sans-serif;
}
